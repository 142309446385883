import { IInstallmentItem } from "models";

export type PaymentDebitsDetails = {
  id_type_partner?: EnumTypePartner;
  id_partner?: string;
};

export type PaymentInstallments = {
  installments_number: number;
  installments_value: string;
  amount: string;
};

export type IdentificationType = {
  name: string;
  document: string;
  phone: string;
  payment_identifier?: string;
  payment_cart_number?: string;
};

export type PaymentBilletType = IdentificationType &
  PaymentDebitsDetails &
  PaymentInstallments & {
    billets: BilletResponseType[];
    debits: (Debits & PlateDebitsDetails & EnergyCityHallDebitsResponse)[];
  };
export enum EnumPaymentForm {
  CREDIT = 1,
  DEBIT = 2,
  PIX = 3,
}

export type LoginRequestType = {
  document: string;
  password: string;
};
export interface PaymentCondition {
  id_condicao_pagamento: number;
  id_forma_pagamento: number;

  qtde_parcelas: number;
  coeficiente_final: number;
  comissao_padrao: number;
  taxa_conveniencia: number;
  mdr: number;
  antecipacao: number;
  prazo_padrao: number;
}

export interface IField {
  bankslip_value: number;
  payment_form: EnumPaymentForm;
  installments_number: number;
}

export interface Partner {
  id_partner: string;
  id_usuario: string;
  id_type_partner: number;
  id_estado?: number;

  name: string;
  image_src: string;
  production: boolean;

  display: boolean;
  name_type_partner: string;
  created_by: string;
  created_at: Date;
  deleted_by?: string;
  deleted_at?: Date;
}
interface PartnerField {
  id_partner_field: string;
  id_partner: string;
  id_type_field: string;

  required: boolean;
  label: string;
  input_key: string;

  created_by: string;
  created_at: Date;
  deleted_by?: string;
  deleted_at?: Date;
}

export type User = {
  usuario: {
    id_usuario: string;
    id_estabelecimento: string;
    cod_usuario?: string;
    nome: string;
    email: string;
    id_estado?: number | null;
  };
  perfis: {
    nome: string;
    id_perfil: number;
  }[];
  responsabilidades: {
    nome: string;
    id_responsabilidade: number;
  }[];
  documento_conta: {
    documento?: string | null;
    verificado?: boolean | null;
    verificado_em?: Date | null;
  };
  authenticated: boolean;
  token: string;
  permissoes: number[];
  partner_fields: PartnerField[];
  partners: Partner[];
  condicao_pagamento: PaymentCondition[];
  whitelabel: Whitelabel;
};

export default interface Whitelabel {
  id_whitelabel: string;
  id_usuario: string;

  name: string;
  primary_color: string;
  secondary_color: string;
  main_logo_url: string;
  favicon_url: string;
  client_url: string;
  domain?: string;
}

export type HandleError = {
  error?: boolean;
  message?: string;
  showMessage?: boolean;
};

export type BilletRequestType = {
  billet_code: string;
  id_establishment?: string;
};

export type UserTotem = {
  _id?: string;
  full_name: string;
  email: string;
  cpf: string;
  created_at: Date;
  deleted_at?: Date;
  deleted_by?: string;
};

export interface Pix {
  id_pix: string;
  emv: string;
  type: string;
  amount: Amount;
  due_date: string;
  key: string;
  merchant: Merchant;
}
interface Merchant {
  name: string;
  city: string;
}

export interface BilletResponseType {
  id_bill: string;
  digitable: string;
  type: TypeBillet;
  assignor: string;
  amount: Amount;
  recipient: Recipient;
  payer: Payer;
  due_date: string;

  isBillet: boolean;
}

export enum TypeBillet {
  CLEARANCE_FORM = "CLEARANCE_FORM",
  CONCESSIONAIRES = "CONCESSIONAIRES",
}
export interface Amount {
  original: number;
  discount: number;
  additional: number;
  total: number;
}

export interface Recipient {
  name: string;
  document: string;
}

export interface Payer {
  name: string;
  document: string;
}
export interface BilletPublicResponseType {
  id_bill: string;
  digitable: string;
  type: string;
  assignor?: string;
  amount: {
    original: number;
    discount: number;
    additional: number;
    total: number;
  };
  recipient?: {
    name: string;
    document: string;
  };
  payer?: {
    name: string;
    document: string;
  };
  due_date?: string;
}

export type INotification = {
  email?: string;
  telefone?: string;
  nome?: string;
  boletos: {
    cod_boleto: string;
    liquidacao_id: string;
    valor: string;
    liquidado_em?: Date;
  }[];
  send_to_email?: boolean;
  send_to_sms?: boolean;
};

export type SavePaymentRequest = PaymentBilletType & {
  id_usuario: string;
  id_estabelecimento: string;
};

export type SavePaymentResponse = {
  id_transacao_totem: string;
  num_pedido: string;
};

export interface GetAllDebitsRequest {
  license_plate?: string;
  document?: string;
  renavam?: string;
  state: EnumState;
  id_establishment: string;
}

export type DebitsTotal = {
  valores: number;
  qtde: number;
};

export interface PlateDebitsDetails {
  ano_fabricacao: number;
  ano_modelo: number;
  chassi: string;
  cor: string;
  marca: string;
  renavam: string;
  totais: DebitsTotal;
}
export type Debits = {
  id_partner: string;
  id_search: string;
  establishment_id: string;
  payment_id: string;
  type: EnumDebitType;
  name: string;
  description: string;
  identifier: string;
  digitable: string;
  year: number;
  amount: number;
  amount_fee: number;
  amount_fine: number;
  amount_discount: number;
  amount_original: number;
  license_plate: string;
  state: EnumState;
  renavam: string;
  due_date: string;
  infraction_date: string;
  payed_at: string;
  expired_at: string;
  id_debit: string;
};

export enum EnumState {
  AC = "AC",
  AL = "AL",
  AP = "AP",
  AM = "AM",
  BA = "BA",
  CE = "CE",
  ES = "ES",
  GO = "GO",
  MA = "MA",
  MT = "MT",
  MS = "MS",
  MG = "MG",
  PA = "PA",
  PB = "PB",
  PR = "PR",
  PE = "PE",
  PI = "PI",
  RJ = "RJ",
  RN = "RN",
  RS = "RS",
  RO = "RO",
  RR = "RR",
  SC = "SC",
  SP = "SP",
  SE = "SE",
  TO = "TO",
  DF = "DF",
}

export enum EnumDebitType {
  SERVICES = "SERVICES",
  TICKET = "TICKET",
  RENAINF_TICKET = "RENAINF_TICKET",
  IPVA = "IPVA",
  LICENSING = "LICENSING",
  IPVA_UNIQUE = "IPVA_UNIQUE",
  IPVA_INSTALLMENT = "IPVA_INSTALLMENT",
  INSURANCE = "INSURANCE",
  EXPIRED_INSURANCE = "EXPIRED_INSURANCE",
  OTHERS = "OTHERS",
}

export type GetPlateDebitsResponse = {
  license_plate: string;
  renavam: string;
  state: EnumState;
  debits: Debits[];
};

export enum EnumTypePartner {
  DETRAN = 1,
  ENERGIA = 2,
  PREFEITURA = 3,
}

export type EnergyCityHallDebitsResponse = {
  codigo_barra: string;
  linha_digitavel?: string;
  identificador_terceiro?: string;
  data_vencimento?: string | Date;
  data_validade?: string | Date;
  data_infracao?: string | Date;
  valor: number;
  descricao?: string;
  valor_original?: number;
  valor_multa?: number;
  valor_juros?: number;
  valor_desconto?: number;
  situacao?: string;
  exercicio?: string;
  numero_controle?: string;
  tipo?: string;
};

export enum EnumTypeAgreements {
  DETRAN = "Detran",
  ENERGIA = "Energia",
}

export type AgreementsReponse = {
  title: EnumTypeAgreements;
  type: EnumTypePartner;
  data: {
    id_partner: string;
    id_usuario: string;
    id_type_partner: number;
    id_estado: number;
    name: string;
    image_src: string;
    production: boolean;
    state_initial: string;
    fields: {
      required: boolean;
      name: NameTypeFields;
      id_partner: string;
      id_type_field: string;
      label: string;
    }[];
  }[];
};

export enum NameTypeFields {
  license_plate = "license_plate",
  document = "document",
  renavam = "renavam",
}

export type PaymentRequest = {
  token?: string;
  session_id: string;
  id_usuario?: string;
  id_usuario_responsavel_pagamento?: string;
  descricao_pagamento?: string;
  billets: Billets[];
  personalInformation: PersonalInformation;
  paymentCards: PaymentCards[];
  billingAddress: BillingAddress;
  partnerSearch?: PartnerSearch;
};

export type PartnerSearch = {
  id_parceiro: string;
  parceiro: string;
  estado?: number;
  document?: string;
  renavam?: string;
  license_plate?: string;
  cod_busca?: string;
  chave_proposta?: string;
};

export type WhiteLabelRequest = {
  id_usuario: string;
};

export type Billets = {
  codigo_barras: string;
  valor_original: number;
  valor_taxa: number;
  valor_desconto: number;
  valor_total: number;
  identificador?: string;
  id_bill: string;
};

export type PersonalInformation = {
  name: string;
  document: string;
  email: string;
  phone: string;
  birth_date: string;
};

export enum EnumFormaPagamento {
  CREDITO = 1,
  DEBITO = 2,
  PIX = 3,
}

export type PaymentCards = {
  type: EnumFormaPagamento;
  amount: number;
  installment: number;
  installment_amount: number;
  name: string;
  // cvc: string;
  expiry_month: string;
  expiry_year: string;
  /** Card owner document */
  document: string;
  /** Card owner birth date */
  birthDate: string;
  phone: string;
  // card_token: string;
  number: string;
  security_code: string;

  id_carrinho_pagamento?: string;
  installment_total?: number;
};

export type Card = {
  number: string;
  due_date: string;
  cvv: string;
  name: string;
  cpfCnpj: string;
  value: number;
  id_charge_option: string;
  installment: IInstallmentItem;
};

export type BillingAddress = {
  postalCode: string;
  street: string;
  complement?: string;
  district: string; // Bairro
  city: string;
  state: string;
  country: string;
  number: string;
};

export enum EnumEstadoCivil {
  SOLTEIRO = "Solteiro",
  CASADO = "Casado",
  SEPARADO = "Separado",
  DIVORCIADO = "Divorciado",
  VIUVO = "Viúvo",
}

export interface UsuarioPermissao {
  id_usuario_permissao: string;

  id_usuario: string;
  id_responsabilidade: number;
  id_aprovacao?: string;
  comissao?: number;

  removido_por?: string;
  removido_em?: Date;

  criado_por: string;
  criado_em: Date;
}

export interface PontoVenda {
  id_ponto_venda: string;
  id_pos_modelo: string;

  serial_number: string;
  logic_number: string;
  // status: boolean;

  criado_por: string;
  criado_em: Date;
}

export interface UsuarioPerfilComplex extends UsuarioPerfil {
  perfil: string;
  criado_por_nome: string;
}

export interface UsuarioPerfil {
  id_usuario_perfil: string;
  id_usuario: string;
  id_perfil: number;

  criado_por: string;
  criado_em: Date | string;

  removido_por?: string;
  removido_em?: Date | string;
}

export interface UsuarioTipoLiquidacao {
  id_usuario_tipo_liquidacao: string;
  id_tipo_liquidacao: number;
  id_usuario: string; // estabelecimento

  webhook_url?: string;
  nome_banco?: string;
  nome_empresa_orgao?: string;
  num_convenio?: string;
  prefixo_agencia?: string;
  digito_prefix_agencia?: string;
  conta_corrente?: string;
  digito_conta_corrente?: string;
  forma_recebimento?: string;
  meio_arrecadacao?: string;
  prefixo_agencia_recebedora?: string;
  numero_banco_empresa_orgao?: string;

  layout_version?: string;

  id_cliente?: string;
  chave_cliente?: string;

  criado_por: string;
  criado_em: Date;
  removido_por?: string;
  removido_em?: Date;
}

export type UsuarioTipoLiquidacaoAtivo = UsuarioTipoLiquidacao & {
  tipo_liquidacao: string;
  criado_por_nome: string;
};

export type AnotacaoComplex = {
  id_anotacao: string;
  titulo: string;
  descricao: string;
  usuario_nome: string;
  criado_por: string;
  criado_em: Date;
};

export interface UsuarioConfiguracao {
  id_usuario_configuracao: string;
  id_usuario: string;

  use_analise_risco: boolean;
  analise_risco?: string;

  criado_por: string;
  criado_em: Date;
  removido_por?: string;
  removido_em?: Date;
}

export interface InstallmentsResponseType {
  id_condicao_pagamento: number;
  id_forma_pagamento: 1 | 2 | 3;
  qtde_parcelas: number;
  coeficiente_final: number;
  comissao_padrao: number;
  taxa_conveniencia: number;
  mdr: number;
  antecipacao: number;
  prazo_padrao: number;
  id_adquirencia: number;
  forma_pagamento: "DEBITO" | "CREDITO" | "PIX";
}

export interface WhiteLabelResponseType {
  id_usuario: string;
  name: string;
  primary_color: string;
  secondary_color: string;
  main_logo_url: string;
  favicon_url: string;
  client_url: string;
  message_content: string;
  message_font_color: string;
  message_font_size: string;
  pay_billet_installments: boolean;
  pay_taxes: boolean;
  pay_vehicle_debts: boolean;
  recharge_cell_phone: boolean;
  action_button_billet: boolean;
  pix_enabled: boolean;
  credit_enabled: boolean;
  debit_enabled: boolean;
  transfer_bank: boolean;
  label_transfer_bank: string;
  run_three_ds: boolean;
  description_transfer_bank: string;
}

export type ResponseFetchQrCode = {
  order_id: string;
  qr_code: string;
  qr_code_text: string;
};

export type BilletRequestFetchQrCode = {
  data_vencimento: string;
  codigo_barras: string;
  linha_digitavel: string;
  valor_boleto: number;
  valor_desconto: number;
  valor_pagamento: number;
  valor_encargos: number;
  valor_outros_acrescimos: number;
  valor_outros_descontos: number;
};

export type RequestFetchQrCode = {
  boletos: BilletRequestFetchQrCode[];
  client: {
    name: string;
    email: string;
    phone: string;
    document: string;
    birth_date?: string;
  };
};

export interface ShipayResponseStatusItems {
  name: string;
  quantity: number;
  unit_price: number;
}

export interface ShipayPaymentStatusResponse {
  order_id: string;
  status: ShipayPaymentStatus;
  paid_amount: number;
  items: ShipayResponseStatusItems[];
}

export interface ShipayPaymentDeleteResponse {
  order_id: string;
  status: ShipayPaymentStatus;
}

export enum ShipayPaymentStatus {
  PENDING = "pending",
  PAID = "paid",
  CANCELED = "canceled",
  REFUNDED = "refunded",
}
export type RequestCreateOrderAuthentication = {
  reference_id: string;
  customer: {
    name: string;
    email: string;
    tax_id: string;
    phones: {
      country: string;
      area: string;
      number: string;
      type: string;
    }[];
  };
  items: {
    reference_id: string;
    name: string;
    quantity: number;
    unit_amount: number;
  }[];
  shipping: {
    address: {
      street: string;
      number: string;
      complement: string;
      locality: string;
      city: string;
      region_code: string;
      country: string;
      postal_code: string;
    };
  };
  notification_urls: string[];
  charges: {
    reference_id: string;
    description: string;
    amount: {
      value: number;
      currency: string;
    };
    payment_method: {
      type: string;
      installments: number;
      capture: boolean;
      soft_descriptor: string;
      card: {
        number: string;
        exp_month: string;
        exp_year: string;
        security_code: string;
        holder: {
          name: string;
        };
      };
      authentication_method: {
        type: string;
        id: string;
      };
    };
  }[];
};

export type ResponseCreateOrderAuthentication = {
  id: string;
  reference_id: string;
  created_at: string;
  customer: {
    name: string;
    email: string;
    tax_id: string;
    phones: {
      type: string;
      country: string;
      area: string;
      number: string;
    }[];
  };
  items: {
    reference_id: string;
    name: string;
    quantity: number;
    unit_amount: number;
  }[];
  shipping: {
    address: {
      street: string;
      number: string;
      complement: string;
      locality: string;
      city: string;
      region_code: string;
      country: string;
      postal_code: string;
    };
  };
  charges: {
    id: string;
    reference_id: string;
    status: string;
    created_at: string;
    paid_at: string;
    description: string;
    amount: {
      value: number;
      currency: "BRL";
      summary: {
        total: number;
        paid: number;
        refunded: number;
      };
    };
    payment_response: {
      code: string;
      message: "SUCESSO" | "FALHA";
      reference: string;
    };
    payment_method: {
      type: "CREDIT_CARD" | "DEBIT_CARD";
      installments: number;
      capture: boolean;
      card: {
        brand: string;
        first_digits: string;
        last_digits: string;
        exp_month: string;
        exp_year: string;
        holder: {
          name: string;
        };
      };
      authentication_method: {
        type: "THREEDS";
        id: string;
      };
      soft_descriptor: string;
    };
    links: [
      {
        rel: "SELF";
        href: string;
        media: "application/json";
        type: "GET";
      },
      {
        rel: "CHARGE.CANCEL";
        href: string;
        media: "application/json";
        type: "POST";
      }
    ];
  }[];
  notification_urls: string[];
  links: [
    {
      rel: "SELF";
      href: string;
      media: "application/json";
      type: "GET";
    },
    {
      rel: "PAY";
      href: string;
      media: "application/json";
      type: "POST";
    }
  ];
};

export type RequestAuthenticate3DS = {
  data: BodyAuthenticate3DS;
};

export type BodyAuthenticate3DS = {
  customer: {
    name: string;
    email: string;
    phones: {
      country: string;
      area: string;
      number: string;
      type: "MOBILE";
    }[];
  };
  paymentMethod: {
    type: string;
    installments: number;
    card: {
      number: string;
      expMonth: string;
      expYear: string;
      holder: {
        name: string;
      };
    };
  };
  amount: {
    value: number;
    currency: "BRL";
  };
  billingAddress: {
    street: string;
    number: string;
    complement: string;
    regionCode: string;
    country: string;
    city: string;
    postalCode: string;
  };
  shippingAddress: {
    street: string;
    number: string;
    complement: string;
    regionCode: string;
    country: string;
    city: string;
    postalCode: string;
  };
  dataOnly: boolean;
  beforeChallenge?: {
    brand: string;
    issuer: string;
    open: boolean;
  };
};

export type ReponseAuthenticate3DS = {
  status:
    | "AUTH_FLOW_COMPLETED"
    | "AUTH_NOT_SUPPORTED"
    | "CHANGE_PAYMENT_METHOD";
  id: string;
};

export type DetranResponse = {
  id_detran: string;
  uf: string;
  logo_url?: string;
};
export interface PartnerList {
  id_partner: string;
  id_type_partner: number;
  id_estado?: number;
  name: string;
  // id_parceiro: EnumParceiro;
  id_usuario: string;
  image_src: string;
  production: boolean;
  display: boolean;
  fields: {
    id_type_field: string;
    label: string;
    name: string;
    required: boolean;
  }[];
}

export type DetranPartnerResponse = {
  id_detran: string;
  uf: string;
  logo_url?: string;
};

export type InforCarConsultResponse = {
  solicitacao: {
    tipo: string;
    dado: string;
    numero_resposta: string;
    tempo: string;
    mensagem: string;
    horario: string;
  };
  resposta: {
    placa: string;
    chassi: string;
    renavam: string;
    marca_modelo: string;
    ano_fabricacao: string;
    ano_modelo: string;
    cor: string;
    combustivel: string;
  };
};

export interface PartnerListType {
  title: string;
  type: number;
  data: PartnerList[];
}

export interface PartnerList {
  id_partner: string;
  id_type_partner: number;
  id_estado?: number;
  name: string;
  // id_parceiro: EnumParceiro;
  id_usuario: string;
  image_src: string;
  production: boolean;
  state_initial: string;
  display: boolean;
  fields: FieldsSearchDebits[];
}

export interface FieldsSearchDebits {
  id_type_field: string;
  label: string;
  name: string;
  required: boolean;
}

export interface FindDetran {
  document?: string;
  renavam?: string;
  license_plate?: string;
  chave_proposta?: string;
  chassi?: string;
}

export interface ConsultDebtsResponse {
  renavam?: string;
  license_plate?: string;
  protocolo?: string;
  marca?: string;
  cor?: string;
  ano_fabricacao?: number;
  ano_modelo?: number | string;
  chassi?: string;
  valor_venal?: string;
  debitos: Debit[];
  tipo_busca?: TipoBusca;
  qtde_parcelas?: number;
  percentual_juros_parcela?: number;
  valor_debito_com_juros?: number;
}

export type TipoBusca = "UNICA" | "PARCELADO";

export type Debit = {
  codigo_barra: string;
  linha_digitavel?: string;
  identificador_terceiro?: string;
  data_vencimento?: string | Date;
  data_validade?: string | Date;
  data_infracao?: string | Date;
  valor: number;
  descricao?: string;
  valor_original?: number;
  valor_multa?: number;
  valor_juros?: number;
  valor_desconto?: number;
  situacao?: string;
  exercicio?: string;
  numero_controle?: string;
  tipo?: string;
  select?: boolean;
  is_license?: boolean;
};

export type dataDebtsRequest = {
  type: EnumTypePartner;
  id_partner: string;
  data: FindDetran;
};

export type dataDetranDebts = {
  document?: string;
  renavam?: string;
  license_plate: string;
  chassi?: string;
  chave_proposta?: string;
};

export type dataDetranDebtsRequest = {
  id_partner: string;
  data: dataDetranDebts;
};

export type HandleErrorFetchDebts = {
  message: string;
  error: boolean;
  fields_required: {
    license_plate: boolean;
    document: boolean;
    renavam: boolean;
    chassi: boolean;
  };
};

export interface ListSimulatorRequest {
  payment_form: EnumPaymentForm;
  amount: number;
  establishment_id: string;
}

export interface ListSimuladorResponse {
  data: DataSimulatorResponse[];
  success: boolean;
}

export interface DataSimulatorResponse {
  installment_number: number;
  installment_amount: number;
  total_amount: number;
  amount: number;
}
