import { removeAccentsAndSpecialCharacteres } from "utils/billet";
import reCaptcha from "./RecaptchaService";
import {
  AgreementsReponse,
  BilletResponseType,
  HandleError,
  InstallmentsResponseType,
  PaymentRequest,
  RequestFetchQrCode,
  ResponseFetchQrCode,
  ShipayPaymentDeleteResponse,
  ShipayPaymentStatusResponse,
  WhiteLabelRequest,
  WhiteLabelResponseType,
  InforCarConsultResponse,
  PartnerListType,
  ListSimulatorRequest,
  ListSimuladorResponse,
  Pix,
} from "./types";
import { api, server } from "services/api";
import { useWhitelabel } from "hooks/useWhitelabel";
import { mapBillFromPublic } from "views/Billet/Search/mapper";
import { useQuery } from "react-query";
import _ from "lodash";
import { toast } from "react-toastify";
class ParcelamosService {
  private api = api;
  private server = server;

  async accountRecovery(
    document: string
  ): Promise<{ okay: true } & HandleError> {
    try {
      const response = await this.api.post("/account-recovery", {
        document,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  async payment(data: PaymentRequest): Promise<PaymentResponse> {
    try {
      const { data: response_data } = await this.server.post(
        "/portal/venda-online",
        data
      );

      return response_data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async getWhiteLabel({
    id_usuario,
  }: WhiteLabelRequest): Promise<WhiteLabelResponseType & any> {
    try {
      const token = await reCaptcha.getRecaptchaToken();

      const response = await this.server.get(`/public/whitelabel`, {
        headers: {
          "id-establishment": id_usuario,
          "x-recaptcha-token": token,
        },
      });
      return response.data;
    } catch (error: any) {
      return {};
    }
  }

  async getInstallments(): Promise<InstallmentsResponseType[] & HandleError> {
    try {
      const response = await this.server.get(
        "/portal/venda-online/condicao_pagamento"
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  async getInstallmentsByUser(): Promise<
    InstallmentsResponseType[] & HandleError
  > {
    try {
      const response = await this.server.get(
        "/portal/venda-online/condicao_pagamento/usuarios"
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  async fetchQrCode(
    data: RequestFetchQrCode
  ): Promise<ResponseFetchQrCode & HandleError> {
    const token = await reCaptcha.getRecaptchaToken();
    try {
      const response = await this.server.post("/portal/pagamento/pix", {
        ...data,
        token,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  async fetchPaymentPixStatus(
    order_id: string
  ): Promise<(ShipayPaymentStatusResponse & HandleError) | void> {
    try {
      if (!order_id) {
        return;
      }
      const response = await this.server.get(
        `/portal/pagamento/pix/${order_id}`
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  async deletePaymentPixStatus(
    order_id: string
  ): Promise<(ShipayPaymentDeleteResponse & HandleError) | void> {
    try {
      if (!order_id) {
        return;
      }
      const response = await this.server.delete(
        `/portal/pagamento/pix/${order_id}`
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }
}

const parcelamosService = new ParcelamosService();

export async function getBilletPublic(
  billet_code: string,
  idEstablishment?: string
): Promise<BilletResponseType & HandleError> {
  const response = await getBillet(billet_code, idEstablishment);

  return mapBillFromPublic(response);
}
export async function getBillet(
  billet_code: string,
  id_establishment?: string
): Promise<BilletResponseType & HandleError> {
  const cleanBilletCode = removeAccentsAndSpecialCharacteres(billet_code);
  const captcha = await reCaptcha.getRecaptchaToken();

  const response = await api.get(`/bill/search/${cleanBilletCode}`, {
    headers: {
      "id-establishment": id_establishment,
      "x-recaptcha-token": captcha,
    },
  });

  return response.data;
}

export async function getPixPublic(emv: string, id_establishment?: string) {
  const captcha = await reCaptcha.getRecaptchaToken();

  const encodedEMV = encodeURIComponent(emv);

  const response = await api.get<Pix>(`/pix/search/${encodedEMV}`, {
    headers: {
      "id-establishment": id_establishment,
      "x-recaptcha-token": captcha,
    },
  });

  return response.data;
}

export async function getCar(
  license_plate: string
): Promise<InforCarConsultResponse & HandleError> {
  try {
    const { data } = await api.get(`/portal/find_car/${license_plate}`);

    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
}

export async function payment(data: PaymentRequest): Promise<PaymentResponse> {
  return await parcelamosService.payment(data);
}

export async function fetchParceiros(): Promise<PartnerListType[]> {
  try {
    const url = `/portal/parceiro`;

    const response = await api.get(url);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
}

export async function deletePaymentPixStatus(
  order_id: string
): Promise<(ShipayPaymentDeleteResponse & HandleError) | void> {
  return await parcelamosService.deletePaymentPixStatus(order_id);
}

export async function getAgreements(): Promise<string[]> {
  try {
    const { data } = await api.get<AgreementsReponse[]>(`/portal/parceiro`);

    const agreements = data.map((agreement) => {
      return agreement.data.map((item) => `${agreement.title} - ${item.name}`);
    });

    return agreements.flat();
  } catch (error: any) {
    return error.response.data;
  }
}

export async function fetchQrCode(
  data: RequestFetchQrCode
): Promise<ResponseFetchQrCode> {
  return await parcelamosService.fetchQrCode(data);
}

export async function fetchPaymentPixStatus(
  order_id: string
): Promise<ShipayPaymentStatusResponse | void> {
  return await parcelamosService.fetchPaymentPixStatus(order_id);
}

export const useGetWhiteLabel = (id_usuario = "") => {
  const { setClient, cleanClient } = useWhitelabel();

  return useQuery(
    ["get-white-label", id_usuario],
    async () => {
      const whitelabel = await parcelamosService.getWhiteLabel({
        id_usuario,
      });

      if (!whitelabel || _.isEmpty(whitelabel)) {
        toast.error("Cliente não encontrado.", {
          onClose: () =>
            (window.location.href = window.location.href.replace(
              `/${id_usuario}`,
              ""
            )),
        });

        cleanClient();

        return { data: {} };
      }

      // Apply whitelabel on abba browser
      document.title = whitelabel.name;
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = whitelabel.favicon_url;
      document.head.appendChild(newFavicon);

      setClient(whitelabel);

      return whitelabel;
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      cacheTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
      enabled: !!id_usuario,
    }
  );
};

export const useGetInstallments = () => {
  return useQuery(
    ["get-installments"],
    () => parcelamosService.getInstallments(),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
    }
  );
};
export const useGetInstallmentsByUser = () => {
  return useQuery(
    ["get-installments"],
    () => parcelamosService.getInstallmentsByUser(),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
    }
  );
};

export async function fetchPagseguroSessionId(): Promise<{ session: string }> {
  const url = `/portal/pagseguro/session`;

  const { data } = await api.get(url);
  return data;
}

export async function fetchOptionsSimulator(
  data: ListSimulatorRequest
): Promise<ListSimuladorResponse> {
  try {
    const url = `/simulator`;

    api.defaults.headers.common["id-establishment"] = data.establishment_id;
    api.defaults.headers.common["api-version"] = 1;

    const response = await api.post<ListSimuladorResponse>(url, data);

    return response.data;
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data));
  }
}

export async function fetchPagseguroPublicKey(): Promise<{
  public_key: string;
}> {
  const url = `/portal/pagseguro/publickey`;

  const { data } = await api.get(url);
  return data;
}

export default new ParcelamosService();
